@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background: #231F20!important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.input-wrapper {
  @apply
  border-[#00000071]
  border-[1px]
  cursor-text
  text-[16px]
}


.button{
  @apply
  text-center
  rounded-md
  bg-[#FCE345]
  text-[14px]
  font-bold
  text-[black]
  border-[1px]
  border-[#5E5E5E]
  flex
  justify-between
}

.page-container{
  overflow: auto;
  height: calc(100vh - 80px);
  padding-top: 8px;
  width: 100%;
}

::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.height-transformer{
  height: calc(100vh - 140px)!important;
  overflow: auto;
}

.wrapper{
  background: #2A2A2D!important;
}

.wrapper-content{
  color:white!important;
  background: none!important;
}

.table-wrapper{
  height: calc(100vh - 250px)!important;
  overflow: auto!important;
  padding-bottom: 140px;
}

.table{
  background: transparent!important;
}

.input-text-color{
  color: white!important;
  font-weight: 300;
}

.input-main{
  border: 1px solid white;
  height: 39px;
  color: white!important;
  background: #2A2A2D!important;
}

.input-main:hover{
  color: white!important;
  opacity: 0.9!important;
  background: #2A2A2D!important;
}

.input-main:focus{
  color: white!important;
  opacity: 1.0!important;
  background: #2A2A2D!important;
}

input[type="date"] {
  background-color: #f0f8ff; /* Цвет фона */
  color: #333; /* Цвет текста */
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background-color: white; /* Цвет индикатора календаря */
  border-radius: 50%; /* Закругление */
}
